import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import {
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { SiteConfig_siteConfig_heatingConf } from "graphql/generated";
import { useLiveMeasurements } from "scenes/site/hooks";

import IframeChart from "components/iframe-chart";
import HeatingTankUnitPreview from "./HeatingTankUnitPreview";


interface IProps {
  siteId: string;
  heatingConf: SiteConfig_siteConfig_heatingConf;
}


const Heating: FunctionComponent<IProps> = ({ siteId, heatingConf }) => {
  const intl = useIntl();
  const { data } = useLiveMeasurements(siteId, "heating");

  return (
    <Flex direction="column" justifyContent="space-between" grow={1}>
      <Grid
        templateColumns={`repeat(${heatingConf.tanks.length}, 1fr)`}
        templateRows={`repeat(${1}, 1fr)`}
        gap={8}
      >
        {heatingConf.tanks.map((tank) => {
          const heatingData = data[`heating_tank_${tank.id}`];

          return (
            <GridItem key={tank.id}>
              <HeatingTankUnitPreview
                title={tank.label || tank.id}
                config={tank}
                data={heatingData}
                siteId={siteId}
                tankId={+tank.id}
              />
            </GridItem>
          );
        })}
      </Grid>
      <Flex>
        <IframeChart
          title={intl.formatMessage({
            id: "heating__chart__active_power",
            defaultMessage: "Heating active power chart",
          })}
          chart={heatingConf.chartA}
          mr={8}
          mb={0}
        />
        <IframeChart
          title={intl.formatMessage({
            id: "heating__chart__tank_temperature",
            defaultMessage: "Heating tank temperature chart",
          })}
          chart={heatingConf.chartB}
          mb={0}
        />
      </Flex>
    </Flex>
  );
};

export default Heating;
