import React, { FunctionComponent, useEffect, useState, useCallback, useMemo } from "react";
import { Button, Box, HStack, VStack, Text, Spinner } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import omit from "ramda/src/omit";
import { max } from "d3";

import { useToasts } from "common/toasts";
import { GET_CONSUMPTION_SCHEDULE, GET_CONSUMPTION_SCHEDULE_NAMES } from "graphql/queries";
import { SET_CONSUMPTION_SCHEDULE_MUTATION } from "graphql/mutations";
import {
  GetConsumptionSchedule,
  GetConsumptionScheduleVariables,
  GetConsumptionScheduleNames,
  GetConsumptionScheduleNamesVariables,
} from "graphql/generated";

import { IDayDef, ISchedule, IScheduleDay } from "./types";
import ScheduleTab from "./ScheduleTab";
import DayTab from "./DayTab";
import DayView from "./DayView";


interface IProps {
  siteId: string;
  uiVersion?: string | null;
}


const WeekSchedule: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushSuccessToast, pushErrorToast } = useToasts();

  const [scheduleId, setScheduleId] = useState<string>("");

  const { data: namesData } = useQuery<
    GetConsumptionScheduleNames,
    GetConsumptionScheduleNamesVariables
  >(GET_CONSUMPTION_SCHEDULE_NAMES, { variables: { siteId } });

  const { data } = useQuery<
    GetConsumptionSchedule,
    GetConsumptionScheduleVariables
  >(GET_CONSUMPTION_SCHEDULE, { variables: { siteId, scheduleId } });

  const [ saveSchedule, { loading: isSaving }] = useMutation(SET_CONSUMPTION_SCHEDULE_MUTATION);

  let weekDayToday = new Date().getDay() - 1;
  const [activeDay, setActiveDay] = useState<number>(weekDayToday >= 0 ? weekDayToday : 6);
  const [state, setState] = useState<ISchedule>({
    noSuggestions: false,
    days: Array.from({length: 7}).map(i => ({moments: [], suggested: [], history: []})),
  })
  const dayTabsDomainMax = useMemo(() => {
    return max(state.days, day => max([
      max(day.moments, moment => moment.energy) || 0,
      max(day.suggested, moment => moment.energy) || 0,
    ]))
  }, [state.days]);

  const daysDef: Array<IDayDef> = [
    {
      id: "mon",
      abbr: intl.formatMessage({
        id: "weekday__mon",
        defaultMessage: "Mon",
      }),
      label: intl.formatMessage({
        id: "weekday__monday",
        defaultMessage: "Monday",
      }),
    },
    {
      id: "tue",
      abbr: intl.formatMessage({
        id: "weekday__tue",
        defaultMessage: "Tue",
      }),
      label: intl.formatMessage({
        id: "weekday__tuesday",
        defaultMessage: "Tuesday",
      }),
    },
    {
      id: "wed",
      abbr: intl.formatMessage({
        id: "weekday__wed",
        defaultMessage: "Wed",
      }),
      label: intl.formatMessage({
        id: "weekday__wednesday",
        defaultMessage: "Wednesday",
      }),
    },
    {
      id: "thu",
      abbr: intl.formatMessage({
        id: "weekday__thu",
        defaultMessage: "Thu",
      }),
      label: intl.formatMessage({
        id: "weekday__thursday",
        defaultMessage: "Thursday",
      }),
    },
    {
      id: "fri",
      abbr: intl.formatMessage({
        id: "weekday__fri",
        defaultMessage: "Fri",
      }),
      label: intl.formatMessage({
        id: "weekday__friday",
        defaultMessage: "Friday",
      }),
    },
    {
      id: "sat",
      abbr: intl.formatMessage({
        id: "weekday__sat",
        defaultMessage: "Sat",
      }),
      label: intl.formatMessage({
        id: "weekday__saturday",
        defaultMessage: "Saturday",
      }),
    },
    {
      id: "sun",
      abbr: intl.formatMessage({
        id: "weekday__sun",
        defaultMessage: "Sun",
      }),
      label: intl.formatMessage({
        id: "weekday__sunday",
        defaultMessage: "Sunday",
      }),
    },
  ];

  useEffect(() => {
    if (!namesData || !namesData.getConsumptionScheduleNames.names.length) {
      return;
    }

    setScheduleId(namesData.getConsumptionScheduleNames.names[0]);
  }, [namesData]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setState({
      noSuggestions: data.getConsumptionSchedule.no_suggestions,
      days: data.getConsumptionSchedule.days.map(day => ({
        moments: day.moments.map(moment => omit(["__typename"], moment)),
        suggested: day.suggestions.map(moment => omit(["__typename"], moment)),
        history: day.history.map(moment => omit(["__typename"], moment)),
      })),
    });
  }, [data]);

  const scheduleName = useCallback((id: string, withPrefix: boolean = true): string => {
    const appendix = withPrefix && id === scheduleId && state.changed ? "*" : "";

    switch (id) {
      case "factory":
        return intl.formatMessage({
          id: "settings__consumption__schedule_name__factory",
          defaultMessage: "factory",
        }) + appendix
      case "furnace":
        return intl.formatMessage({
          id: "settings__consumption__schedule_name__furnace",
          defaultMessage: "furnace",
        }) + appendix
      default:
        return id + appendix;
    }
  }, [intl, scheduleId, state.changed]);

  const pickSchedule = useCallback((value: string) => {
    if (value === scheduleId) return;

    const question = intl.formatMessage({
      id: "settings__consumption__schedule_name__abandon_unsaved_schedule",
      defaultMessage: "Discard the changes you've made in {name} schedule?",
    }, {
      name: scheduleName(scheduleId, false),
    });

    if (!state.changed || (state.changed && window.confirm(question))){
      setScheduleId(value);
    }
  }, [state.changed, intl, scheduleId, scheduleName]);

  return (
    <>
      <HStack spacing={0} alignItems="stretch">

        {!namesData && <Box p={3}><Spinner size="xl" /></Box>}
        {namesData && <>
        <VStack
          direction="column"
          borderRightColor="gray.500"
          borderRightWidth={1}
        >
        {namesData?.getConsumptionScheduleNames?.names.map((name, i) => {
          return (
            <ScheduleTab
              index={i}
              scheduleId={name}
              isActive={name === scheduleId}
              onClick={() => pickSchedule(name)}
            >
              {scheduleName(name)}
            </ScheduleTab>
          )
        })}
        </VStack>

        {!data && <Box p={3}><Spinner size="xl" /></Box>}
        {data && <>
        <VStack
          pl={3}
          alignItems="flex-end"
        >
          {daysDef.map((day, i) => (
              <DayTab
                key={day.id}
                dayDef={day}
                index={i}
                activeDay={{ value: activeDay, set: setActiveDay }}
                dayState={state.days[i]}
                noSuggestions={state.noSuggestions}
                dataDomain={[0, dayTabsDomainMax ? 1.2 * dayTabsDomainMax : 1]}
                isActive={i === activeDay}
              />
            ))}
        </VStack>
        <DayView
          activeDay={activeDay}
          schedule={{ value: state, set: setState }}
          noSuggestions={state.noSuggestions}
          borderLeftColor="gray.500"
          borderLeftWidth={1}
        />
        </> }

        </> }
      </HStack>
      {namesData && data && <>
        <Box display="flex" mt={8} justifyContent="space-between">
          <HStack alignItems="center">
            <InfoIcon flexGrow={0} color={"enposol.500"} />
            <Text fontSize="sm">
              {intl.formatMessage({
                id: "settings__consumption__interaction_help",
                defaultMessage: "Dragging in the chart above to setup create consumption points. Adjust existing points by dragging them. Click a point to remove it.",
              })}
            </Text>
          </HStack>
          <Button
            size="md"
            colorScheme="yellow"
            px={10}
            flexGrow={0}
            isLoading={isSaving}
            isDisabled={!state.changed}
            onClick={() => {
              const schedule: ISchedule = {
                noSuggestions: state.noSuggestions,
                days: state.days.map(day => omit(["suggested", "history"], day) as IScheduleDay)
              }

              saveSchedule({
                variables: {
                  siteId,
                  scheduleId,
                  days: schedule.days,
                  noSuggestions: schedule.noSuggestions,
                },
              })
                .then(() => {
                  pushSuccessToast(
                    intl.formatMessage({
                      defaultMessage: "Successfully saved",
                      id: "site__settings__save_success",
                    })
                  );
                  setState({
                    noSuggestions: state.noSuggestions,
                    days: state.days,
                  })
                })
                .catch((reason) => {
                  pushErrorToast(
                    intl.formatMessage({
                      defaultMessage: "Saving settings failed ({reason})",
                      id: "site__settings__save_error",
                    }, {
                      reason: String(reason),
                    })
                  );
                })
            }}
          >
            {intl.formatMessage({
              id: "settings___apply",
              defaultMessage: "Apply",
            })}
          </Button>
        </Box>
      </>}
    </>
  );
};

export default WeekSchedule;
