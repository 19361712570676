import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";

import omit from "ramda/src/omit";

import { useToasts } from "common/toasts";
import {
  useSettingsTable,
  SettingsTable,
} from "components/settings-table";
import { IItem } from "components/settings-table/types";

import { useQuery, useMutation } from "@apollo/client";
import { SET_HEATING_TANK_SETTINGS } from "graphql/mutations";
import { GET_HEATING_TANK_SETTINGS } from "graphql/queries";
import {
  GetHeatingTankSettings,
  GetHeatingTankSettingsVariables,
} from "graphql/generated";


interface IProps {
  siteId: string;
  tankId: number;
  toggle: Function;
}


const HeatingTankUnitSettings: FunctionComponent<IProps> = ({
  siteId,
  tankId,
  toggle,
}) => {
  const intl = useIntl();
  const { pushSuccessToast, pushErrorToast } = useToasts();

  const items: Array<IItem> = [
    {
      id: "minTemp",
      type: "float",
      unit: "°C",
      name: intl.formatMessage({
        id: "heating_tank__min_temp",
        defaultMessage: "Minimal temperature",
      })
    },
    {
      id: "maxTemp",
      type: "float",
      unit: "°C",
      name: intl.formatMessage({
        id: "heating_tank__max_temp",
        defaultMessage: "Maximal temperature",
      })
    },
  ];

  const { data: controlsData, loading } = useQuery<GetHeatingTankSettings, GetHeatingTankSettingsVariables>(
    GET_HEATING_TANK_SETTINGS,
    {
      variables: { siteId, tankId },
      errorPolicy: "all",
    }
  );

  const { state, tableProps } = useSettingsTable(
    items,
    omit(["__typename"], controlsData?.getHeatingTankSettings)
  );

  const [setSettings, { loading: isSaving }] = useMutation(SET_HEATING_TANK_SETTINGS);

  return (
    <Box
      w={"full"}
      bg={useColorModeValue("gray.200", "gray.800")}
      overflow={"hidden"}
    >
      <SettingsTable {...tableProps} isDisabled={isSaving} variant="compact" />
      <Box p={1} textAlign="center">
        <Button
          size="sm"
          w={160}
          colorScheme="yellow"
          flex={1}
          isLoading={loading || isSaving}
          onClick={() => {
            setSettings({
              variables: {
                siteId,
                tankId,
                settings: state as any,
              },
            })
              .then(() => {
                toggle();
                pushSuccessToast(
                  intl.formatMessage({
                    defaultMessage: "Successfully saved",
                    id: "site__settings__save_success",
                  })
                );
              })
              .catch((reason) => {
                pushErrorToast(String(reason), null);
              });
          }}
        >
          {intl.formatMessage({
            id: "settings___apply",
            defaultMessage: "Apply",
          })}
        </Button>
      </Box>
    </Box>
  );
};

export default HeatingTankUnitSettings;
