import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Collapse,
  IconButton,
  Image,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";

import { SiteConfig_siteConfig_heatingConf_tanks } from "graphql/generated";

import Card from "components/card";
import CardTitle from "components/card-title";
import StatsTable from "components/stats-table";
import HeatingTankUnitSettings from "./HeatingTankUnitSettings";
import HeatingTankStatus, { HEATING_TANK_STATUS } from "./HeatingTankStatus";
import HeatingTankMedium from "./HeatingTankMedium";
import { displayId } from "common/stringUtils"


interface HeatingTankData {
  status: number;
  temp_top: number;
  temp: number;
  temp_bottom: number;
  active_power: number;
  min_temp: number;
  max_temp: number;
}

interface IProps {
  siteId: string;
  tankId: number;
  title: string;
  config: SiteConfig_siteConfig_heatingConf_tanks;
  data: HeatingTankData;
}


export function getImageSrc(data: HeatingTankData) {
  const temperature = data?.temp < data?.min_temp ? "malo" : data?.temp > data?.max_temp ? "moc" : "ok";
  const heating = data?.active_power > 0 ? "zahriva" : "nezahriva";

  return `/images/svg/aku-nadrz-${heating}-${temperature}.svg`;
}


const HeatingTankUnitPreview: FunctionComponent<IProps> = ({
  siteId,
  tankId,
  title,
  config,
  data,
}) => {
  const intl = useIntl();
  const { isOpen: controlsOpen, onToggle: toggleControls } = useDisclosure();

  return (
    <Card>
      <CardTitle
        title={intl.formatMessage({
          id: "heating_tank___title",
          defaultMessage: "Tank {id}"
        }, {
          id: displayId(title),
        })}
      >
        <IconButton
          aria-label="Settings"
          bg={useColorModeValue("white", "gray.800")}
          size="sm"
          m={1}
          onClick={toggleControls}
          icon={<SettingsIcon />}
          title={intl.formatMessage({
            id: "heating_tank__settings",
            defaultMessage: "Settings",
          })}
        />
      </CardTitle>
      <Stack
        p={6}
        pt={0}
      >
        <Stack direction="row" w="full">
          <Box position="relative">
            <Image src={getImageSrc(data)} boxSize="150px" flex={1} />
          </Box>

          <Box flex={2}>
            <StatsTable
              inline
              rows={[
                {
                  label: intl.formatMessage({
                    id: "heating_tank__active_power",
                    defaultMessage: "Active power",
                  }),
                  unit: "kW",
                  decimals: 1,
                  value: data?.active_power,
                },
                {
                  label: intl.formatMessage({
                    id: "heating_tank__middle_temperature",
                    defaultMessage: "Temperature",
                  }),
                  unit: "°C",
                  decimals: 1,
                  value: data?.temp,
                },
                {
                  label: intl.formatMessage({
                    id: "heating_tank__medium",
                    defaultMessage: "Medium",
                  }),
                  value: <HeatingTankMedium medium={config.medium} volume={config.volume} />,
                  unit: "L",
                },
                {
                  label: intl.formatMessage({
                    id: "heating_tank__top_temperature",
                    defaultMessage: "Upper part temperature",
                  }),
                  unit: "°C",
                  decimals: 1,
                  value: data?.temp_top,
                },
                {
                  label: intl.formatMessage({
                    id: "heating_tank__bottom_temperature",
                    defaultMessage: "Lower part temperature",
                  }),
                  unit: "°C",
                  decimals: 1,
                  value: data?.temp_bottom,
                },
                {
                  label: intl.formatMessage({
                    id: "heating_tank__status",
                    defaultMessage: "Status",
                  }),
                  value: <HeatingTankStatus state={data?.status} />,
                },
              ]}
            />
          </Box>
        </Stack>
      </Stack>
      <Collapse in={controlsOpen} unmountOnExit>
        <HeatingTankUnitSettings
          siteId={siteId}
          tankId={tankId}
          toggle={toggleControls}
        />
      </Collapse>
    </Card>
  );
};

export default HeatingTankUnitPreview;
