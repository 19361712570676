import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
import { getImageSrc } from "../../heating/HeatingTankUnitPreview"
import HeatingTankStatus from "../../heating/HeatingTankStatus"
import { useNavigate } from "react-router-dom";


interface IProps {
  baseUrl: string;
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}


const HeatingCard: FunctionComponent<IProps> = ({ data, config, baseUrl }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__heating____title",
        defaultMessage: `{count, plural,
          one {Heating}
          other {Heatings}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={getImageSrc(data)}
      onClick={() => {
        navigate(`${baseUrl}/heating`);
      }}
    >
        <StatsTable
          inline
          rows={[
            {
                label: intl.formatMessage({
                id: "dashboard__heating__active_power",
                defaultMessage: "Active power",
                }),
                unit: "kW",
                decimals: 1,
                value: data?.active_power,
            },
            {
                label: intl.formatMessage({
                id: "dashboard__heating__temperature",
                defaultMessage: "Temperature",
                }),
                unit: "°C",
                decimals: 1,
                value: data?.temp,
            },
            {
                label: intl.formatMessage({
                id: "dashboard__heating__status",
                defaultMessage: "Status",
                }),
                value: <HeatingTankStatus state={data?.status} />,
            },
          ]}
        />
    </BaseCard>
  );
};

export default HeatingCard;
