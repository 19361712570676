import React, { FunctionComponent } from "react";
import { Badge, Skeleton, Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";


interface IProps {
  state?: number;
}

export const HEATING_TANK_STATUS = {
  NONE: 0,
  OK: 1,
  OFFLINE: 2,
  ERROR: 3,
}


const HeatingTankStatus: FunctionComponent<IProps> = ({ state }) => {
  const intl = useIntl();

  switch (state) {
    case HEATING_TANK_STATUS.NONE:
      return (
        <Badge variant={"outline"} colorScheme={"gray"} title={intl.formatMessage({
            id: "heating_tank__status__none",
            defaultMessage: "No status",
          })}>
          <Box display="inline-block" w="2" h="3" />
        </Badge>
      )
    case HEATING_TANK_STATUS.OK:
      return (
        <Badge variant="solid" colorScheme={"green"}>
          {intl.formatMessage({
            id: "heating_tank__status__ok",
            defaultMessage: "OK",
          })}
        </Badge>
      )
    case HEATING_TANK_STATUS.OFFLINE:
      return (
        <Badge variant="solid" colorScheme={"gray"}>
          {intl.formatMessage({
            id: "heating_tank__status__offline",
            defaultMessage: "OFFLINE",
          })}
        </Badge>
      )
    case HEATING_TANK_STATUS.ERROR:
      return (
        <Badge variant="solid" colorScheme={"red"}>
          {intl.formatMessage({
            id: "heating_tank__status__error",
            defaultMessage: "ERROR",
          })}
        </Badge>
      )
    default:
      return (
        <Skeleton minW={5} w={10} h={3} />
      )
  }
};

export default HeatingTankStatus;
