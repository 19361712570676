import React, {
  Dispatch,
  FunctionComponent,
  MouseEvent,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import assert from "assert";
import { debounce, delay } from "lodash";
import {
  useColorModeValue,
  useTheme,
  Box,
  BoxProps,
  Button,
} from "@chakra-ui/react";
import {
  curveStepAfter, curveMonotoneX, extent, min, max,
} from "d3";

import Chart, { Area, Line, Points, AxisX, AxisY } from "scenes/site/components/chart";
import { makeScale } from "scenes/site/components/chart/utils";
import {
  DatumMouseEventHandler,
  DatumMouseEvent,
  IDimensions,
  IPlotData,
  IStageContext,
 } from "scenes/site/components/chart/types";

import { IScheduleMoment, ISchedule, IConsumptionHistoryMoment } from "./types";
import { fillData, sortedMomentsIndex } from "./utils"
import PointLabels from "scenes/site/components/chart/components/PointLabels";
import { useIntl } from "react-intl";


interface IProps extends BoxProps {
  schedule: IScheduleState;
  activeDay: number;
  noSuggestions: boolean;
}

interface IScheduleState {
  value: ISchedule;
  set: Dispatch<SetStateAction<ISchedule>>;
}


const DayView: FunctionComponent<IProps> = ({
  activeDay,
  schedule,
  noSuggestions,
  ...rest
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const tc = theme.colors;
  const containerRef = useRef<HTMLTableCellElement>(null);
  const [windowSize, setWindowSize] = useState<IDimensions>({ width: 0, height: 0 });
  const [dragging, setDragging] = useState<boolean>(false);
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [draggedPoint, setDraggedPoint] = useState<number>(-1);
  const [stageSpecs, setStageSpecs] = useState<IStageContext>({
    rangeX: [0, 0],
    rangeY: [0, 0],
    offsetX: [0, 0],
    offsetY: [0, 0],
  })

  const strokeWidth = 2;
  const pointRadius = 10;
  const pointClickPrecisionTolerance = useMemo(() => pointRadius / 2, [pointRadius]);

  const days = useMemo(() => schedule.value.days.flat(), [schedule.value]);
  const moments = useMemo(() => days[activeDay].moments.flat(), [days, activeDay]);
  const suggested = useMemo(() => days[activeDay].suggested.flat(), [days, activeDay]);
  const history = useMemo(() => days[activeDay].history.flat(), [days, activeDay]);

  const dataExtent = useMemo(() => {
    const minimum = 500;
    const maximum = 600; // TODO change this to be dynamic based on the scheduled hardware actual capacity
    const extents = [
      minimum,
      (extent<number>(moments.map(d => d.energy * 1.1)) as number[])[1],
      (extent<number>(history.map(d => d.highest_energy * 1.1)) as number[])[1],
    ]
    return min([max(extents) || minimum, maximum]);
  }, [moments, history]);

  const dataSpecs = useMemo(() => ({
    domainX: [0, 24 * 60 - 1],
    domainY: [0, dataExtent],
    strokeWidth: strokeWidth,
  }), [strokeWidth, dataExtent]);

  const scaleX = useMemo(() => makeScale("x", stageSpecs, dataSpecs.domainX).clamp(true), [stageSpecs, dataSpecs.domainX]);
  const scaleY = useMemo(() => makeScale("y", stageSpecs, dataSpecs.domainY).clamp(true), [stageSpecs, dataSpecs.domainY]);
  const clickScaleX = useMemo(() => scaleX.invert, [scaleX]);
  const clickScaleY = useMemo(() => scaleY.invert, [scaleY]);

  const data = useMemo<IPlotData[]>(() => moments.map((value: IScheduleMoment) => ({
    x: value.hour * 60 + value.minute,
    y: value.energy,
  })), [moments]);

  const suggestedData = useMemo<IPlotData[]>(() => suggested.map((value: IScheduleMoment) => ({
    x: value.hour * 60 + value.minute,
    y: value.energy,
  })), [suggested]);

  const avgConsumptionData = useMemo<IPlotData[]>(() => history.map((value: IConsumptionHistoryMoment) => ({
    x: value.hour * 60 + value.minute + 30,
    y: value.avg_energy,
  })), [history]);

  const consumptionNarrowSpreadData = useMemo<IPlotData[]>(() => history.map((value: IConsumptionHistoryMoment) => ({
    x: value.hour * 60 + value.minute + 30,
    y: value.high_energy,
    y0: value.low_energy,
  })), [history]);

  const consumptionWideSpreadData = useMemo<IPlotData[]>(() => history.map((value: IConsumptionHistoryMoment) => ({
    x: value.hour * 60 + value.minute + 30,
    y: value.highest_energy,
    y0: value.lowest_energy,
  })), [history]);

  const filledData = useMemo(() => fillData(data), [data]);

  const pointToMoment = useCallback((x: number, y: number): IScheduleMoment => {
    const minutes = clickScaleX(x);
    const value = clickScaleY(y);

    return {
      hour: Math.floor(minutes / 60),
      minute: Math.round(minutes % 60),
      energy: Math.round(value),
    }
  }, [clickScaleX, clickScaleY]);

  const findMomentIndexByTime = useCallback((time: number): number => {
    return moments.findIndex(moment => {
      return Math.abs(time - (moment.hour * 60 + moment.minute)) < pointClickPrecisionTolerance;
    });
  }, [moments, pointClickPrecisionTolerance]);

  const onPointGrab = useCallback<DatumMouseEventHandler<SVGSVGElement>>((event: DatumMouseEvent<SVGSVGElement>) => {
    const momentIndex = findMomentIndexByTime(event.d.x);

    setMouseDown(true);
    setDraggedPoint(momentIndex);
  }, [findMomentIndexByTime]);

  const onPointMove = useCallback<MouseEventHandler<SVGSVGElement>>((event: MouseEvent<SVGSVGElement>) => {
    if (!mouseDown) return;

    assert(containerRef.current);

    const boundingRect = containerRef.current.getBoundingClientRect();
    const x = event.clientX - boundingRect.x;
    const y = event.clientY - boundingRect.y;
    const moment = pointToMoment(x, y);

    if (draggedPoint >= 0) moments.splice(draggedPoint, 1);

    const momentIndex = sortedMomentsIndex(moments, moment);

    setDragging(true);
    setDraggedPoint(momentIndex);
    moments.splice(momentIndex, 0, moment);
    days[activeDay].moments = moments;
    schedule.set({ days, noSuggestions, changed: true });
  }, [mouseDown, pointToMoment, draggedPoint, moments, days, noSuggestions, activeDay, schedule]);

  const onPointRelease = useCallback<MouseEventHandler<SVGSVGElement>>((event: MouseEvent<SVGSVGElement>) => {
    setMouseDown(false);
    setDraggedPoint(-1);
    delay(() => setDragging(false), 100);
  }, []);

  const onNewPointMaybe = useCallback<MouseEventHandler<SVGSVGElement>>((event: MouseEvent<SVGSVGElement>) => {
    setMouseDown(true);
  }, []);

  const onPointDelete = useCallback<DatumMouseEventHandler<SVGSVGElement>>((event: DatumMouseEvent<SVGSVGElement>) => {
    if (dragging) {
      onPointRelease(event);
    } else {
      const momentIndex = findMomentIndexByTime(event.d.x);

      moments.splice(momentIndex, 1);
      days[activeDay].moments = moments;
      schedule.set({ days, noSuggestions, changed: true });
    }
  }, [dragging, onPointRelease, findMomentIndexByTime, moments, days, noSuggestions, activeDay, schedule]);

  const resetMoments = useCallback(() => {
    moments.splice(0);
    days[activeDay].moments = moments;
    schedule.set({ days, noSuggestions, changed: true });
  }, [moments, days, schedule, noSuggestions, activeDay]);

  useEffect(() => {
    const containerEl = containerRef.current;
    assert(containerEl);

    const resizeWindow = () => {
      setWindowSize({
        width: containerEl.offsetWidth,
        height: 550, //containerEl.offsetHeight,
      });
    };
    resizeWindow();

    const debouncedResizeWindow = debounce(resizeWindow, 5, { leading: true, trailing: true });

    window.addEventListener('resize', debouncedResizeWindow);

    return () => {
      window.removeEventListener('resize', debouncedResizeWindow);
    }
  }, []);

  useEffect(() => {
    setStageSpecs({
      rangeX: [0, windowSize.width],
      rangeY: [0, windowSize.height],
      offsetX: [70, 70],
      offsetY: [50, 50],
    });
  }, [windowSize, pointRadius]);

  return (
    <Box
      ref={containerRef}
      alignItems="space-between"
      position="relative"
      flex={1}
      width={100} // this forces the box to scale down and allow chart resize in both directions
      {...rest}
    >
      {moments.length !== 0 && !noSuggestions && <>
        <Button
          size="sm"
          position="absolute"
          right="0"
          colorScheme="enposol"
          variant="ghost"
          onClick={() => resetMoments()}
        >
          {intl.formatMessage({
            id: "settings__consumption__schedule__use_suggested",
            defaultMessage: "Reset to automatic",
          })}
        </Button>
      </>}
      <Chart
        {...windowSize}
        curve={curveStepAfter}
        offsetX={stageSpecs.offsetX}
        offsetY={stageSpecs.offsetY}
        onMouseDown={onNewPointMaybe}
        onMouseUp={onPointRelease}
        onMouseMove={onPointMove}
      >
        <Area data={filledData} {...dataSpecs} fill={useColorModeValue(tc.gray["300"], tc.gray["700"])} />
        <Line data={filledData} {...dataSpecs} stroke={useColorModeValue(tc.gray["700"], tc.gray["300"])} />
        <Area data={fillData(consumptionNarrowSpreadData)} {...dataSpecs} curve={curveMonotoneX} fill={tc.gray["500"]} opacity={0.2} />
        <Area data={fillData(consumptionWideSpreadData)} {...dataSpecs} curve={curveMonotoneX} fill={tc.gray["500"]} opacity={0.1} />
        <Line data={fillData(avgConsumptionData)} {...dataSpecs} curve={curveMonotoneX} stroke={tc.gray["500"]} strokeWidth={1} opacity={1} />
        <Line data={noSuggestions ? [] : fillData(suggestedData)} {...dataSpecs} stroke={useColorModeValue(tc.enposol["400"], tc.enposol["300"])} opacity={0.6} strokeWidth={10} />
        <Points
          data={data}
          {...dataSpecs}
          fill={useColorModeValue(tc.gray["700"], tc.gray["300"])}
          r={pointRadius}
          onMouseDown={onPointGrab}
          onDoubleClick={onPointDelete}
        />
        <PointLabels
          data={data}
          {...dataSpecs}
          fill={useColorModeValue(tc.gray["700"], tc.gray["300"])}
          color={useColorModeValue(tc.gray["200"], tc.gray["800"])}
        />
        <AxisX scale={scaleX} ticks={Array.from({ length: 9 }).map((_, i) => i * 3 * 60)} y={windowSize.height - stageSpecs.offsetY[1]} />
        <AxisY scale={scaleY.nice()} x={stageSpecs.offsetX[0]} />
      </Chart>
    </Box>
  );
};

export default DayView;
