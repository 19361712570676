export const snakeToCamelCase = (str: string): string =>
  str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

export const displayId = (id: number | string): string => {
  switch (typeof id) {
    case "string":
      if (id === String(parseInt(id))) return String(parseInt(id) + 1);
      else return id;
    default:
      return String(id + 1);
  }
}

export const minutesToHhMm = (minutes: number = 0): string => {
  const hh = Math.round(minutes / 60);
  const mm = minutes % 60;
  const pad = (value: number): string => value.toString().padStart(2, '0');

  return `${hh}:${pad(mm)}`;
}
